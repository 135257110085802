import { api } from '@pages/_app';
import axios from 'axios';
import { getCookie } from '../@v2';
import { logError } from './loggers';
import { StatusCodes } from 'http-status-codes';
import { store } from '@store/store';
import { apiAuthRefresh, apiGetUserData } from './api';

axios.defaults.headers.common['X-Hallow-Version'] = process.env.ACCESS_API_VERSION;
axios.defaults.headers.common['X-App-Version'] = process.env.ACCESS_CLIENT_VERSION;
axios.defaults.headers.common['X-Platform'] = 'web',
axios.defaults.headers.common['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York',
axios.defaults.withCredentials = true;

/*
    Request interceptor
*/
axios.interceptors.request.use((config) => {
    if (!config.headers) config.headers = {};

    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Hallow-Language'] = store?.getState()?.session?.locale ?? 'en';
    config.headers['Accept-Language'] = config.headers['X-Hallow-Language'];
    config.headers['X-CID'] = getCookie({ key: '_fbc' });
    config.headers['X-PID'] = getCookie({ key: '_fbp' });

    if (store?.getState()?.user?.oauth?.access_token) {
        // eslint-disable-next-line dot-notation
        config.headers['Authorization'] = `Bearer ${store.getState().user.oauth.access_token}`;
    }
    
    return config;
});

/*
    Response interceptor
*/
const refreshLimiter = 3;
const limiterDuration = 30000;
let curLimit = 0;
setInterval(() => { if (curLimit !== 0) curLimit--; }, limiterDuration);

axios.interceptors.response.use((response) => {
    return response;
}, async (err) => {
    if (err.response?.status !== StatusCodes.UNAUTHORIZED
        || err.response?.config?.url === `${ api.url }/oauth/refresh` || typeof window === 'undefined') {
        logError('Axios Response Interceptor', err);
        return Promise.reject(err?.response?.data ?? err);
    }

    // reauthorize user with refresh token
    try {
        if (!(curLimit < refreshLimiter)) throw '- Tried to refresh auth too many times.';
        curLimit++;

        const res = await apiAuthRefresh();
        if (res?.access_token) {
            if (err.config.url !== `${ api.url }/me`) await apiGetUserData(res.access_token);
        } else {
            throw res;
        }
    } catch (interceptorErr) {
        logError('Axios Response Interceptor', interceptorErr);
        return Promise.reject(err?.response?.data ?? err);
    }
    
    // delay original request until authorization refresh is complete
    return axios.request(err.config);
});

export default axios;