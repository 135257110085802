import * as device from 'react-device-detect';
import { getCookie } from '../getCookie';
import type { RequestInitProps } from './types';
import { deviceDetectToDeviceType, getDeviceScreen } from '@utilities';
import { store } from '@store/store';

const { getState } = store;

export type RequestProps = RequestInitProps & {
    url: string;
}

const isHeaders = (obj: any): obj is Headers => obj instanceof Headers;

export const request = ({ requestInit: { headers: requestHeaders, ...requestInit } = {}, url }: RequestProps) => {
    const { formatted: screenSize } = getDeviceScreen();
    const headers: RequestInit['headers'] = {
        'Content-Type': 'application/json',
        'X-App-Version': process.env.ACCESS_CLIENT_VERSION,
        'X-Hallow-Version': process.env.ACCESS_API_VERSION,
        'X-Platform': 'web',
        'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York',
        'X-Device-Screen-Size': screenSize,
        'X-Device-Type': deviceDetectToDeviceType(device.deviceType),
        'X-Device-OS-Version': `${ device.osName } ${ device.osVersion }`,
        'X-CID': getCookie({ key: '_fbc' }),
        'X-PID': getCookie({ key: '_fbp' })
    };
    const token = getState().user?.oauth?.access_token;
    if (token) {
        // eslint-disable-next-line dot-notation
        headers['Authorization'] = `Bearer ${token}`;
    }
    if (Array.isArray(requestHeaders)) {
        for (const [header, value] of requestHeaders) {
            headers[header] = value;
        }
    } else if (isHeaders(requestHeaders)) {
        requestHeaders.forEach((value, key) => {
            headers[key] = value;
        });
    } else if (requestHeaders) {
        for (const [header, value] of Object.entries(requestHeaders)) {
            headers[header] = value;
        }
    }
    
    return fetch(url, {
        credentials: 'include',
        headers,
        ...requestInit
    });
};